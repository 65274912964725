<template>
  <section class="eLearningElementary container">
    <div class="eLearningElementary__cardContainer">
      <ELearningImageCard
        v-for="(elementaryItem, index) in elementaryFilterLists"
        :key="index"
        :item="elementaryItem"
        :type="'elementary'"
      />

      <hr class="eLearningElementary__hr" />
    </div>
  </section>
</template>

<script>
import ELearningImageCard from '@/components/ELearning/ELearningImageCard.vue'

// Api
import { apiGetELearning } from '@/api/webAPI'

export default {
  components: {
    ELearningImageCard
  },
  data() {
    return {
      elementaryLists: []
    }
  },
  async created() {
    const eLearningResponse = await apiGetELearning('elementary')
    this.elementaryLists = eLearningResponse.data.data

    // 給banner過濾年份用
    this.$store.commit('setELearning', this.elementaryLists)
  },
  computed: {
    elementaryFilterLists() {
      const filterYear = this.$store.state.eLearningYear
      if (!filterYear) return this.elementaryLists
      return this.elementaryLists.filter(item => item.year === filterYear)
    }
  }
}
</script>

<style lang="scss">
.eLearningElementary {
  padding: 4.9rem 0;
  overflow: hidden;

  &__cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -1.8rem;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin: 10.4rem 0 10rem 0;
  }
}

@media (max-width: 768px) {
  .eLearningElementary {
    padding: 4.9rem 2rem;
  }
}
</style>
