<template>
  <div class="eLearningImageCard">
    <router-link
      :to="{ name: 'ELearningDetail', query: { title: item.title, type } }"
    >
      <div :alt="`${item.title}`" class="eLearningImageCard__container clickable" :style="bgStyle">
        <div
          v-if="item.image.length === 0"
          class="eLearningImageCard__titleWithoutImage"
        >
          {{ item.title }}
        </div>
        <h3 class="eLearningImageCard__title">{{ item.title }}</h3>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '小金的奇幻旅程',
        image: 'https://picsum.photos/id/684/388/259',
        id: 1,
        introduction:
          '新北市立黃金博物館105年與教育局國民教育輔導團的教師群合作，規劃「小金的奇幻旅程」、「阿金伯勇闖黃金城」、「建築與景觀-不刻意交錯的時空印記」以及「戰爭與和平」四個以國小中高年級為對象的主題教案，將音樂、視覺、表演藝術融入「水金九礦業遺址」，希望透過教學現場教師的協助及認同，強化礦業人文資產保存與生活教育結合的理念。',
        designDesc: `本課程透過對於金屬礦業的研究與了解，及觀察金瓜石礦業遺跡的圖片，讓學生將現實生活與遺跡做一聯結。
      1. 讓遺址產生更多有脈絡、邏輯的深刻意義。
      2. 引發學生認同保護家鄉礦業遺址的想法，進而產生有意義、有創意、說故事的能力。`
      })
    },
    type: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    bgStyle() {
      if (this.item.image.length === 0) return 'background-color: #D0A805;'
      return `background-image: url(${this.item.image[0]});`
    }
  }
}
</script>

<style lang="scss">
.eLearningImageCard {
  width: 33%;
  padding: 1.8rem;

  &__container {
    padding-bottom: 66.75%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  &__title {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: white;
    background: rgba(35, 24, 21, 0.9);
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    padding: 0.8rem 0;
  }

  &__titleWithoutImage {
    font-size: 2.2rem;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(calc(-50% - 1.95rem));
    width: 100%;
    text-align: center;
    letter-spacing: 0.2rem;
  }
}

@media (max-width: 768px) {
  .eLearningImageCard {
    width: 100%;
  }
}
</style>
